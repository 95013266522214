import React, { useState } from "react";
import "./card-personalizado.css";
import { PiCheckFatFill, PiQuestionFill } from "react-icons/pi";

import LINHAPERSONALIZADO from "../../../assets/img/inscricoes/linha-personalizado.svg";

import personalizado1 from "../../../assets/img/inscricoes/personalizado1.png";
import personalizado2 from "../../../assets/img/inscricoes/personalizado2.png";
import personalizado3 from "../../../assets/img/inscricoes/personalizado3.png";
import personalizado4 from "../../../assets/img/inscricoes/personalizado4.png";
import personalizado5 from "../../../assets/img/inscricoes/personalizado5.png";
import personalizado6 from "../../../assets/img/inscricoes/personalizado6.png";

const CardPersonalizado = ({ activeTab }) => {
  const [activeImage, setActiveImage] = useState(null);

  const handleIconClick = (imageIndex) => {
    setActiveImage(activeImage === imageIndex ? null : imageIndex);
  };

  return (
    <div className="card-personalizado">
      {/*       <div className="desconto">
        <img src={desconto} alt="" />
      </div> */}
      <div className="semi-circle-personalizado"></div>
      <div className="card-personalizado-content">
        <div className="personalizado-top-txt">
          <h4>
            Concurso <font color="#231f20">Canguru</font>
          </h4>
          <small>Personalizado</small>
          <p>
            Personalize a experiência e eleve o
            <br />
            nível do aprendizado matemático!
          </p>
        </div>
        <div className="personalizado-mid-txt">
          <div className="personalizado-item0">
            <div className="item0-txt">
              <PiCheckFatFill size={14} color="#49A942" />
              <p>
                Tudo o que o Concurso Canguru
                <br />
                Padrão oferece
              </p>
            </div>
            <div className="item0-img">
              <img src={LINHAPERSONALIZADO} alt="" id="linha" />
            </div>
          </div>
          <div className="personalizado-item2">
            <PiCheckFatFill size={14} color="#49A942" />
            <p>
              <b>Placa de Participação.</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(1)}
                size={16}
                color="#49A942"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 1 && (
              <img src={personalizado1} className="img-personalizado1" alt="" />
            )}
          </div>
          <div className="personalizado-item2">
            <PiCheckFatFill size={14} color="#49A942" />
            <p>
              <b>Revista Canguru do Ano.</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(2)}
                size={16}
                color="#49A942"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 2 && (
              <img src={personalizado2} className="img-personalizado2" alt="" />
            )}
          </div>
          <div className="personalizado-item2">
            <PiCheckFatFill size={14} color="#49A942" />
            <p>
              <b>Relatório de Desempenho dos Estudantes.</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(3)}
                size={16}
                color="#49A942"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 3 && (
              <img src={personalizado3} className="img-personalizado3" alt="" />
            )}
          </div>
          <div className="personalizado-item2">
            <PiCheckFatFill size={14} color="#49A942" />
            <p>
              <span>NEW</span> <b>Nova Área do Estudante.</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(4)}
                size={16}
                color="#49A942"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 4 && (
              <img src={personalizado4} className="img-personalizado4" alt="" />
            )}
          </div>
          <div className="personalizado-item2">
            <PiCheckFatFill size={14} color="#49A942" />
            <p>
              <b>Valor único por escola.</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(5)}
                size={16}
                color="#49A942"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 5 && (
              <img src={personalizado5} className="img-personalizado5" alt="" />
            )}
          </div>
          <div className="personalizado-item2">
            <PiCheckFatFill size={14} color="#49A942" />
            <p>
              <b>Medalhas (item opcional).</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(6)}
                size={16}
                color="#49A942"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 6 && (
              <img src={personalizado6} className="img-personalizado6" alt="" />
            )}
          </div>
        </div>
        <div className="personalizado-low-txt">
          <div className="valor-personalizado-solo">
            <div className="valor-personalizado-riscado">
              {activeTab === "Escolas Públicas" && <small>R$343</small>}
              {activeTab === "Escolas Privadas" && <small>R$787</small>}
            </div>
            <div className="destaque-valor-personalizado-solo">
              <p>Valor</p>
              {activeTab === "Escolas Públicas" && <p>R$325,85</p>}
              {activeTab === "Escolas Privadas" && <p>R$747,65</p>}
            </div>
            <div className="valor-personalizado-desconto">
              <small>5% de desconto em Janeiro</small>
            </div>
          </div>
        </div>
        <div className="btn btn-card-personalizado">
          {/* <p>Em Breve</p> */}
          <a
            href="https://gestao.upmat.com.br/sales/kangaroo"
            target="_blank"
            rel="noopener noreferrer"
          >
            Inscrever-se
          </a>
        </div>
      </div>
    </div>
  );
};

export default CardPersonalizado;
