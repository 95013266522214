import React, { useState } from "react";
import "./card-combo.css";
import { PiCheckFatFill, PiQuestionFill } from "react-icons/pi";

import linhaCombo from "../../../assets/img/inscricoes/linha-combo.svg";

import combo1 from "../../../assets/img/inscricoes/combo1.png";
import combo2 from "../../../assets/img/inscricoes/combo2.png";
import combo3 from "../../../assets/img/inscricoes/combo3.png";
import combo4 from "../../../assets/img/inscricoes/combo4.png";
import combo5 from "../../../assets/img/inscricoes/combo5.png";
import combo6 from "../../../assets/img/inscricoes/combo6.png";

const CardCombo = ({ activeTab }) => {
  const [activeImage, setActiveImage] = useState(null);

  const handleIconClick = (imageIndex) => {
    setActiveImage(activeImage === imageIndex ? null : imageIndex);
  };

  return (
    <div className="card-combo">
      <div className="semi-circle-combo">
        <p>Acrescente ao seu pacote</p>
      </div>
      <div className="card-combo-content">
        <div className="combo-top-txt">
          <h4>
            Plataforma
            <br />
            Canguru Experience
          </h4>
          <p>
            Canguru de Matemática na
            <br />
            Sala de Aula o ano todo!
          </p>
        </div>
        <div className="combo-mid-txt">
          <div className="combo-item0">
            <div className="combo-item0-main">
              <p>
                Transforme o aprendizado:
                <br />
                professores mais preparados,
                <br />
                estudantes mais motivados!
              </p>
            </div>
            <div className="combo-item0-txt">
              <PiCheckFatFill size={14} color="#642EE4" />
              <p>
                Tudo o que o Concurso Canguru
                <br />
                Padrão oferece
              </p>
            </div>
            <img src={linhaCombo} alt="" />
          </div>
          <div className="combo-item2">
            <PiCheckFatFill size={14} color="#642EE4" />
            <p>
              <b>Trilha de Preparação.</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(1)}
                size={16}
                color="#642EE4"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 1 && (
              <img src={combo1} className="img-combo1" alt="" />
            )}
          </div>
          <div className="combo-item2">
            <PiCheckFatFill size={14} color="#642EE4" />
            <p>
              <b>KangooTreino.</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(2)}
                size={16}
                color="#642EE4"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 2 && (
              <img src={combo2} className="img-combo2" alt="" />
            )}
          </div>
          <div className="combo-item2">
            <PiCheckFatFill size={14} color="#642EE4" />
            <p>
              <b>Atividades de Sondagem.</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(3)}
                size={16}
                color="#642EE4"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 3 && (
              <img src={combo3} className="img-combo3" alt="" />
            )}
          </div>
          <div className="combo-item2">
            <PiCheckFatFill size={14} color="#642EE4" />
            <p>
              <b>Aula Canguru.</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(4)}
                size={16}
                color="#642EE4"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 4 && (
              <img src={combo4} className="img-combo4" alt="" />
            )}
          </div>
          <div className="combo-item2">
            <PiCheckFatFill size={14} color="#642EE4" />
            <p>
              <b>Acervo Completo.</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(5)}
                size={16}
                color="#642EE4"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 5 && (
              <img src={combo5} className="img-combo5" alt="" />
            )}
          </div>
          <div className="combo-item2">
            <PiCheckFatFill size={14} color="#642EE4" />
            <p>
              <b>Formação Continuada.</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(6)}
                size={16}
                color="#642EE4"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 6 && (
              <img src={combo6} className="img-combo6" alt="" />
            )}
          </div>
          <div className="combo-item2">
            <PiCheckFatFill size={14} color="#642EE4" />
            <p>
              <b>Acesso</b> para até 10 Professores.
            </p>
          </div>
        </div>
        <div className="combo-low-txt">
          <div className="combo-valor">
            <p>Valor</p>
            {activeTab === "Escolas Públicas" && <p>R$570,00</p>}
            {activeTab === "Escolas Privadas" && <p>R$1.140,00</p>}
          </div>
          <div className="combo-pacote">
            <div className="combo-pacote-left">
              <p>
                5<span>%</span>
              </p>
              <small>desconto</small>
            </div>
            <div className="combo-pacote-right">
              <p>no pacote escolhido</p>
            </div>
          </div>
        </div>
        <div className="btn btn-card-combo">
          {/* <p>Em Breve</p> */}
          <a
            href="https://gestao.upmat.com.br/sales/kangaroo"
            target="_blank"
            rel="noopener noreferrer"
          >
            Inscrever-se
          </a>
        </div>
      </div>
    </div>
  );
};

export default CardCombo;
