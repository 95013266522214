import React, { useState } from "react";
import "./card-padrao.css";
import { PiCheckFatFill, PiQuestionFill } from "react-icons/pi";

import padrao1 from "../../../assets/img/inscricoes/padrao1.png";
import padrao2 from "../../../assets/img/inscricoes/padrao2.png";
import padrao3 from "../../../assets/img/inscricoes/padrao3.png";
import padrao4 from "../../../assets/img/inscricoes/padrao4.png";

const CardPadrao = ({ activeTab }) => {
  const [activeImage, setActiveImage] = useState(null);

  const handleIconClick = (imageIndex) => {
    setActiveImage(activeImage === imageIndex ? null : imageIndex);
  };

  return (
    <div className="card-padrao">
      <div className="semi-circle-padrao"></div>
      <div className="card-padrao-content">
        <div className="padrao-top-txt">
          <h4>
            Concurso <font color="#231f20">Canguru</font>
          </h4>
          <small>Padrão</small>
          <p>
            Desperte o potencial de seus
            <br />
            alunos com a matemática!
          </p>
        </div>
        <div className="padrao-mid-txt">
          <div className="padrao-item1">
            <PiCheckFatFill size={14} color="#009ddc" />
            <p>
              <b>Participação garantida</b> no Concurso Canguru,
              <br />
              <b>conforme Regulamento</b>.
            </p>
          </div>
          <div className="padrao-item2">
            <PiCheckFatFill size={14} color="#009ddc" />
            <p>
              <b>Certificação</b> digital para estudantes e professores.
            </p>
          </div>
          <div className="padrao-item2">
            <PiCheckFatFill size={14} color="#009ddc" />
            <p>
              <b>Relatório de Desempenho da Escola.</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(1)}
                size={16}
                color="#009ddc"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 1 && (
              <img src={padrao1} className="img-padrao1" alt="" />
            )}
          </div>
          <div className="padrao-item2">
            <PiCheckFatFill size={14} color="#009ddc" />
            <p>
              <span>NEW</span> <b>Nova Área do Estudante.</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(2)}
                size={16}
                color="#009ddc"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 2 && (
              <img src={padrao2} className="img-padrao2" alt="" />
            )}
          </div>
          <div className="padrao-item2">
            <PiCheckFatFill size={14} color="#009ddc" />
            <p>
              <b>Valor único por escola.</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(3)}
                size={16}
                color="#009ddc"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 3 && (
              <img src={padrao3} className="img-padrao3" alt="" />
            )}
          </div>
          <div className="padrao-item2">
            <PiCheckFatFill size={14} color="#009ddc" />
            <p>
              <b>Medalhas (item opcional).</b>{" "}
              <PiQuestionFill
                onClick={() => handleIconClick(4)}
                size={16}
                color="#009ddc"
                style={{ cursor: "pointer" }}
              />
            </p>
            {activeImage === 4 && (
              <img src={padrao4} className="img-padrao4" alt="" />
            )}
          </div>
        </div>
        <div className="padrao-low-txt">
          <div className="valor-padrao-solo">
            <div className="destaque-valor-padrao-solo">
              <p>Valor</p>
              {activeTab === "Escolas Públicas" && <p>R$149</p>}
              {activeTab === "Escolas Privadas" && <p>R$399</p>}
            </div>
          </div>
        </div>
        <div className="btn btn-card-padrao">
          {/* <p>Em Breve</p> */}
          <a
            href="https://gestao.upmat.com.br/sales/kangaroo"
            target="_blank"
            rel="noopener noreferrer"
          >
            Inscrever-se
          </a>
        </div>
      </div>
    </div>
  );
};

export default CardPadrao;
